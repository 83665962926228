.apiAuthorization {
  font-family: PingFangSC-Regular;
}
.apiAuthorization .apiList {
  margin: 0.2rem 0;
  margin-bottom: 0;
  min-height: calc(91.8vh + 54px);
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
.apiAuthorization .apiList li {
  padding: 0.2rem 0;
  margin: 0 0.4rem;
  border-bottom: 1px solid #F1F1F1;
  overflow: hidden;
}
.apiAuthorization .apiList li .img-icon {
  height: 0.61rem;
  margin-right: 0.22rem;
  vertical-align: -0.2rem;
}
.apiAuthorization .apiList li .spot {
  float: right;
}
.apiAuthorization .apiList li .spot img {
  width: 0.28rem;
}
.apiAuthorization .apiList li span {
  font-size: 0.25rem;
  color: #02172E;
}
