
























































































































    .apiAuthorization {
		font-family: PingFangSC-Regular;
        .apiList {
            margin: 0.2rem 0;
			margin-bottom: 0;
			min-height:calc(91.8vh + 54px);
			border-top-left-radius: 0.32rem;
			border-top-right-radius: 0.32rem;
			// height: 100%;

            li {
                padding: 0.2rem 0;
				margin: 0 0.4rem;
                border-bottom: 1px solid #F1F1F1;
                overflow: hidden;

                .img-icon {
                    height: 0.61rem;
                    margin-right: 0.22rem;
                    vertical-align: -0.2rem;
                }

                .spot {
                    float: right;

                    img {
                        width: 0.28rem;
                    }
                }

                span {
                    font-size: 0.25rem;
                    color: #02172E;
                }
            }

            li:last-child {
                // border-bottom: none;
            }
        }
    }

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;